import React, { useState, useEffect, useContext } from "react";
import moment from "moment";

// Component imports
import AmmoniteProtectedIcon from "../../../Assets/SVG/SBReportFinancialHealthIcons/Protected/Protected";
import AmmonitePropertyIcon from "../../../Assets/SVG/SBReportFinancialHealthIcons/Property/Property";
import AmmoniteWillIcon from "../../../Assets/SVG/SBReportFinancialHealthIcons/Will/Will";
import AmmoniteGoalRecommendation from "../../../components/suitabilityReportComponents/goalRecommendation/goalRecommendation";
import AmmoniteReportGoalCard from "../../../containers/reportContainers/ReportGoalCard/reportGoalCard";
import AmmoniteInvestmentSummary from "../../../components/suitabilityReportComponents/investmentSummary/investmentSummary";
import AmmoniteInvestmentGoalRiskLevels from "../../../components/suitabilityReportComponents/investmentGoalRiskLevels/investmentGoalRiskLevels";
import AmmoniteInvestmentAfterRecommendations from "../../../components/suitabilityReportComponents/investmentAfterRecommendations/investmentAfterRecommendations";
import AmmoniteCharge from "../../../components/suitabilityReportComponents/charge/charge";
import AmmoniteExistingPension from "../../../components/suitabilityReportComponents/existingPension/existingPension";
import AmmoniteExistingInvestment from "../../../components/suitabilityReportComponents/existingInvestment/existingInvestment";
import SuitabilityReportContext from "../../../Utils/contexts/suitabilityReportContext";
import AmmoniteCreateDoc from "../../../Assets/SVG/SBContextBarIcons/CreateDoc/createDoc";
import docxCreator from "../../../Utils/docx/docxCreator";
import { getIsClient } from "../../../Utils/data/getIsClient";
import { formatNumbers } from "../../../Utils/data/numberFormatting";
import { yearsToRetirement } from "../../../Utils/data/getStatePensionAge";
import {
  getSignature,
  getMortgageTerm,
  getLifeCover,
  getRetirementDate,
  getFundProviders,
  checkInFundProviders,
  areTherePensionContributions,
  areThereOtherInvestmentContributions,
  getIsISARetained,
  getIsPensionRetained,
  getCapitalisedType,
  getInvestmentSolutionsGroupedByGoals,
  getCashSolutionsGroupedByGoals,
  getInvestmentSolutionsGroupedByGoalsRisk,
  getAllInvestmentSolutionsDeduped,
  getWrapperContributionAmount,
  getContributionTypeExists,
  getGoalImportance,
  getGoalRankedByImportance,
  getIsNotEveryGoalFocused,
  getDoesGoalFocusExist,
  getRiskMatrix,
  getCapacityForLoss,
  getHasGoalBeenAllocated,
  getInvestmentSolutionsByGoal,
  getGoalRiskProfile,
  getInvestmentHeldIn,
  getCustomerAge,
  getEmploymentSituation,
  getNoChildren,
  budgetAndAffordabilityValues,
  getDeficitSurplus,
  getCustomerAttitudeToRisk,
  getCapacityForLossStatement,
  getAnyEmployerContributions,
  areTherePensionContributionsCustomer,
  getInflationOnRetirementGoal,
} from "../../../Utils/data/suitabilityReportDerivedData";
import {
  getAmount,
  getTotalImplementationChargeOnPot,
  getTotalPortfolioValue,
  getTotalImplementationCharge,
  getTotalWeightedCharge,
  getTotalPoundCharge,
  getISAGIAPensionCharges,
  getAdviserCharges,
  getPensionCharges,
  getISAGIACharges,
  getRecommendedPolicy,
  getTotalPortfolioValueMonthlyContributions,
} from "../../../Utils/data/suitabilityReportCharges";
import { setPreviewed } from "../../../Utils/reducers/suitabilityReportActions";

// Styling
import "./suitabilityReportPDF.css";
import AmmoniteContext from "../../../Utils/contexts/AmmoniteContext";
function SuitabilityReportPDF() {
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;

  const [dateNow, setDateNow] = useState(Date.now());
  const [sbrConfigIndex, setSbrConfigIndex] = useState(0);
  const [checkboxColour, setCheckboxColour] = useState("");
  const [filledCheckboxColour, setFilledCheckboxColour] = useState("");
  const [nextColour, setNextColour] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");

  const saveHandler = async () => {};

  const hasPreviewed = () => {
    dispatch({ type: setPreviewed, payload: true });
    saveHandler();
  };

  const getOwners = (investment) => {
    if (investment.owner) {
      let _ownerFirstNames = [];
      _ownerFirstNames = investment.owner
        .map((owner) => {
          return state.people.map((person) => {
            if (person._id === owner._id && owner.isOwner === true) {
              return person.firstName;
            } else {
              return null;
            }
          });
        })
        .flat();

      return _ownerFirstNames.filter(Boolean).join(" & ");
    }
  };

  let _owners = state.newPensionsAdvice.map((pension) => getOwners(pension));
  let uniqueOwners = [...new Set(_owners)];

  const createNewDocX = async (state) => {
    try {
      let _nonClientData = context.state.nonClientData;
      let _config = _initConfig.sBRConfig[sbrConfigIndex];
      let nextColour = getIsClient(_initConfig.AppSettings.nextColour);
      let checkboxColour = getIsClient(_initConfig.AppSettings.checkboxColour);
      let initConfig = _initConfig;
      let advisers = _initConfig["Advisors"];
      docxCreator(
        state,
        `${state._id}.docx`,
        _config,
        _nonClientData,
        nextColour,
        checkboxColour,
        initConfig,
        advisers
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    hasPreviewed();
    if (sbrConfigIndex === 0 && state.sBRConfigIndex) {
      setSbrConfigIndex(state.sBRConfigIndex);
    }
    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
    }
    if (_initConfig.AppSettings) {
      setFilledCheckboxColour(
        getIsClient(_initConfig.AppSettings.filledCheckboxColour)
      );
    }
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
  }, [
    sbrConfigIndex,
    checkboxColour,
    filledCheckboxColour,
    nextColour,
    textPrimaryColor,
  ]);

  return (
    <div className="sbPDF">
      {status === "success" &&
        state &&
        state.people &&
        _initConfig &&
        sbrConfigIndex !== undefined && (
          <div className="sbPDFPage">
            <div className="sbPDFHeader">
              <div className="docXButton" onClick={() => createNewDocX(state)}>
                <AmmoniteCreateDoc />
              </div>
              <img
                src={getIsClient(
                  _initConfig.sBRConfig[sbrConfigIndex].sBRPDFHeader
                )}
                alt="Company Logo"
                width="1350"
                height="auto"
              ></img>
            </div>
            <div className="sbPDFTitle" style={{ color: nextColour }}>
              <div className="topLine">FINANCIAL PLAN AND RECOMMENDATIONS</div>
              <div className="middleLine">for</div>
              <div className="bottomLine">
                {state.people[0] && state.people[0].firstName.toUpperCase()}{" "}
                {state.people[0] && state.people[0].lastName.toUpperCase()}
                {state.people[1] && " AND "}
                {state.people[1] &&
                  state.people[1].firstName.toUpperCase()}{" "}
                {state.people[1] && state.people[1].lastName.toUpperCase()}
              </div>
              {status === "success" && (
                <div className="sessionDate">
                  {moment(dateNow).format("D")} {moment(dateNow).format("MMMM")}{" "}
                  {moment(dateNow).format("YYYY")}
                </div>
              )}
              <div className="sbrTitleLine">
                <svg
                  width="1152"
                  height="2"
                  viewBox="0 0 1152 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    y1="1"
                    x2="1152"
                    y2="1"
                    stroke={nextColour}
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </div>
            <div className="sbrBody">
              {/* INTRODUCTION */}
              {_initConfig &&
                _initConfig.sBRConfig[sbrConfigIndex].introductionText
                  .default &&
                _initConfig.sBRConfig[sbrConfigIndex].introductionText
                  .default[0].paragraph && (
                  <div>
                    <div
                      className="sbrSubheading"
                      style={{ color: nextColour }}
                    >
                      Introduction
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1">
                      {state.people[0].firstName}
                      {state.people[1]
                        ? " and " + state.people[1].firstName
                        : ""}
                      {
                        getIsClient(
                          _initConfig.sBRConfig[sbrConfigIndex].introductionText
                        )[0].paragraph
                      }
                    </div>
                    {getIsClient(
                      _initConfig.sBRConfig[sbrConfigIndex].introductionText
                    )
                      .slice(1)
                      .map((item) => {
                        return (
                          <div className="recommendationsParagraph recommendationsParagraph1">
                            {item.paragraph}
                          </div>
                        );
                      })}
                  </div>
                )}
              {/* TIMING */}
              <div
                className="sbrBlueSubSubHeading"
                style={{ color: checkboxColour }}
              >
                Timing
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1">
                {getIsClient(
                  _initConfig.sBRConfig[sbrConfigIndex].timingText
                ).map((item) => item.paragraph)}
              </div>
              {/* RECOMMENDATIONS */}
              <div className="sbrSubheading" style={{ color: nextColour }}>
                Recommendations summary
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1">
                We’ve based our recommendations for you on your financial goals,
                to give you the best chance of reaching them.
              </div>
              <div>
                {state.newPensionsAdvice &&
                  state.newISAsAdvice &&
                  state.newGIAsAdvice &&
                  state.newCashAdvice &&
                  state.goals.map((goal, index) => (
                    <div className="goalRecommendationOuterContainer">
                      <AmmoniteGoalRecommendation
                        goalAllocated={getHasGoalBeenAllocated(
                          goal,
                          state.newPensionsAdvice,
                          state.newISAsAdvice,
                          state.newGIAsAdvice,
                          state.newCashAdvice
                        )}
                        inflation={
                          context.state.nonClientData.Assumptions.inflation
                        }
                        inflationLinked={goal.inflationLinked}
                        goalTimeHorizon={
                          goal.goalType === "Retirement pot"
                            ? yearsToRetirement(
                                goal.goalTimeHorizon,
                                state.people[0].dob || state.people[1].dob
                              )
                            : goal.goalTimeHorizon
                        }
                        amountRequiredToday={goal.amountRequiredToday}
                        goalType={goal.goalType}
                        goalName={goal.goalName}
                        goalAmount={goal.goalAmount ? goal.goalAmount : 0}
                        goalAmountUnderAdvice={(
                          Math.round(
                            getAmount(
                              state.newPensionsAdvice,
                              state.newISAsAdvice,
                              state.newGIAsAdvice,
                              state.newCashAdvice,
                              index,
                              true
                            ) * 100
                          ) / 100
                        ).toFixed()}
                        goalMonthlyContributions={(
                          Math.round(
                            getAmount(
                              state.newPensionsAdvice,
                              state.newISAsAdvice,
                              state.newGIAsAdvice,
                              state.newCashAdvice,
                              index,
                              false
                            ) * 100
                          ) / 100
                        ).toFixed()}
                        goalInvestmentPlatform={getInvestmentSolutionsByGoal(
                          state.newPensionsAdvice,
                          state.newISAsAdvice,
                          state.newGIAsAdvice,
                          index,
                          "provider",
                          state.clientAttitudeToRisk.attitudeToRiskScore,
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex].riskCategories
                          )["YEARS TO GOAL"],
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex]
                              .investmentStrategy
                          )
                        )}
                        goalInvestmentSolution={getInvestmentSolutionsByGoal(
                          state.newPensionsAdvice,
                          state.newISAsAdvice,
                          state.newGIAsAdvice,
                          index,
                          "investmentSolution",
                          state.clientAttitudeToRisk.attitudeToRiskScore,
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex].riskCategories
                          )["YEARS TO GOAL"],
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex]
                              .investmentStrategy
                          )
                        )}
                        goalRiskProfile={getGoalRiskProfile(
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex].riskCategories
                          )["YEARS TO GOAL"],
                          goal.goalTimeHorizon,
                          state.clientAttitudeToRisk.attitudeToRiskScore
                        )}
                        goalHeldIn={getInvestmentHeldIn(
                          state.newPensionsAdvice,
                          state.newISAsAdvice,
                          state.newGIAsAdvice,
                          state.newCashAdvice,
                          index
                        )}
                        goalFocused={goal.focused}
                      />
                    </div>
                  ))}
              </div>
              {/* ONGOING SERVICE */}
              <div
                className="sbrSubheading spacingLarge"
                style={{ color: nextColour }}
              >
                Our ongoing service
              </div>
              {_initConfig.sBRConfig[sbrConfigIndex].ongoingServiceText &&
                getIsClient(
                  _initConfig.sBRConfig[sbrConfigIndex].ongoingServiceText
                ).map((item) => {
                  return (
                    <div className="recommendationsParagraph recommendationsParagraph1">
                      {item.paragraph}
                    </div>
                  );
                })}
              {/* CURRENT SITUATION */}
              <div className="sbrSubheading" style={{ color: nextColour }}>
                Your current situation
              </div>
              {status === "success" && state.people[0].dob && (
                <div className="recommendationsParagraph recommendationsParagraph1">
                  {state.people[0].firstName} you are aged{" "}
                  {getCustomerAge(state.people[0]["dob"])}. You are{" "}
                  {getEmploymentSituation(state.people[0].workSituation)}.
                </div>
              )}
              {status === "success" && state.people[1] && (
                <div className="recommendationsParagraph recommendationsParagraph1">
                  {state.people[1].firstName} you are aged{" "}
                  {getCustomerAge(state.people[1]["dob"])}. You are{" "}
                  {getEmploymentSituation(state.people[1].workSituation)}.
                </div>
              )}
              {status === "success" && state.children.length > 0 && (
                <div className="recommendationsParagraph recommendationsParagraph1 sbrChildren">
                  You have {getNoChildren(state.children.length)}.
                  {state.children.map((child) => (
                    <div className="recommendationsParagraph recommendationsParagraph1 sbrChildren">
                      &nbsp;{child.name} is {child.age}
                      {child.financiallyDependent ? (
                        <div className="recommendationsParagraph recommendationsParagraph1 sbrChildren">
                          {" "}
                          and is financially dependent.
                        </div>
                      ) : (
                        "."
                      )}
                    </div>
                  ))}
                </div>
              )}
              {status === "success" && (
                <div className="recommendationsParagraph recommendationsParagraph1">
                  {state.additionalCustomerInformation.why}
                </div>
              )}
              {status === "success" && (
                <div className="recommendationsParagraph recommendationsParagraph1">
                  {state.additionalCustomerInformation.what}
                </div>
              )}
              {status === "success" && (
                <div className="recommendationsParagraph recommendationsParagraph1">
                  {state.additionalCustomerInformation.notes}
                </div>
              )}
              {/* YOUR BUDGET AND AFFORDABILITY */}
              <div className="sbrSubheading" style={{ color: nextColour }}>
                Your budget and affordability
              </div>
              {status === "success" && (
                <div className="recommendationsParagraph recommendationsParagraph1">
                  It's important to keep enough money in cash for emergencies,
                  in an 'emergency pot'. For your emergency pot, we recommend
                  that you keep enough in a current account to cover expenditure
                  for 3-6 months. For you, this means keeping{" "}
                  {formatNumbers(
                    budgetAndAffordabilityValues(
                      "customerExpenditureRange",
                      state
                    )
                  )}{" "}
                  in a current account.
                </div>
              )}
              {status === "success" &&
                budgetAndAffordabilityValues("agreedLumpSum", state) > 0 && (
                  <div className="recommendationsParagraph recommendationsParagraph1">
                    We’ve agreed to provide recommendations for a total lump sum
                    contribution of £
                    {formatNumbers(
                      budgetAndAffordabilityValues("agreedLumpSum", state)
                    )}
                    . This will leave you with £
                    {formatNumbers(
                      budgetAndAffordabilityValues("cashStartingBalance", state)
                    )}{" "}
                    in accessible cash. You've confirmed that you feel this is
                    affordable.
                  </div>
                )}
              {status === "success" && (
                <div className="recommendationsParagraph recommendationsParagraph1">
                  Your total net monthly income is £
                  {formatNumbers(
                    budgetAndAffordabilityValues("netMonthlyIncome", state)
                  )}{" "}
                  per month.
                </div>
              )}
              {status === "success" && (
                <div className="recommendationsParagraph recommendationsParagraph1">
                  Your total monthly expenditure is £
                  {formatNumbers(
                    budgetAndAffordabilityValues("monthlyExpenditure", state)
                  )}{" "}
                  per month.
                </div>
              )}
              {status === "success" && (
                <div className="recommendationsParagraph recommendationsParagraph1">
                  You therefore have a {formatNumbers(getDeficitSurplus(state))}{" "}
                  per month.
                </div>
              )}
              {status === "success" &&
                (state.sbReportExpenditures.agreedMonthlyBudget > 0 ||
                  state.sbReportExpenditures.agreedMonthlyBudget > 0) && (
                  <div className="recommendationsParagraph recommendationsParagraph1">
                    We have agreed a budget of £
                    {formatNumbers(
                      budgetAndAffordabilityValues("agreedMonthlyBudget", state)
                    )}{" "}
                    per month, towards your financial goals. You've confirmed
                    that this is affordable for now and for the forseeable
                    future, but if this changes please let us know.
                  </div>
                )}
              {/* RISK TOLERANCE AND CAPACITY */}
              <div className="sbrSubheading" style={{ color: nextColour }}>
                {getIsClient(
                  _initConfig.sBRConfig[sbrConfigIndex].riskHeaderText
                )}
              </div>
              {getIsClient(_initConfig.sBRConfig[sbrConfigIndex].riskText).map(
                (item) => {
                  return (
                    <div className="recommendationsParagraph recommendationsParagraph1">
                      {item.paragraph}
                    </div>
                  );
                }
              )}
              <div className="sbrSubheading" style={{ color: nextColour }}>
                {getIsClient(
                  _initConfig.sBRConfig[sbrConfigIndex].riskToleranceHeaderText
                )}
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1">
                {getIsClient(
                  _initConfig.sBRConfig[sbrConfigIndex].riskToleranceText
                )}
              </div>
              {status === "success" && (
                <div
                  className="sbrBlueSubSubHeading"
                  style={{ color: checkboxColour }}
                >
                  {
                    getIsClient(
                      _initConfig.sBRConfig[sbrConfigIndex]
                        .riskToleranceCategories
                    )[state.clientAttitudeToRisk.attitudeToRiskScore].text
                  }
                </div>
              )}
              <div className="recommendationsParagraph recommendationsParagraph1">
                {getIsClient(
                  _initConfig.sBRConfig[sbrConfigIndex].riskToleranceCategories
                )[
                  state.clientAttitudeToRisk.attitudeToRiskScore
                ].reportParagraphs.map((item) => {
                  return (
                    <div className="recommendationsParagraph recommendationsParagraph1">
                      {item.paragraph}
                    </div>
                  );
                })}
              </div>
              {/* RISK TABLE */}
              {status === "success" &&
              getIsClient(
                _initConfig.sBRConfig[sbrConfigIndex].riskToleranceCategories
              )[state.clientAttitudeToRisk.attitudeToRiskScore].imageurl ? (
                <div className="riskMatrix">
                  <img
                    src={
                      getIsClient(
                        _initConfig.sBRConfig[sbrConfigIndex]
                          .riskToleranceCategories
                      )[state.clientAttitudeToRisk.attitudeToRiskScore].imageurl
                    }
                    alt="Risk Table"
                    width="auto"
                  ></img>
                </div>
              ) : (
                ""
              )}
              <div
                className="sbrBlueSubSubHeading"
                style={{ color: checkboxColour }}
              >
                Our discussion
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1">
                {state.clientAttitudeToRisk.clientCommentsOnATR}
              </div>
              <div className="sbrSubheading" style={{ color: nextColour }}>
                {getIsClient(
                  _initConfig.sBRConfig[sbrConfigIndex].riskCapacityHeaderText
                )}
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1">
                {status === "success" && (
                  <div className="recommendationsParagraph recommendationsParagraph1">
                    {state.clientCapacityForLoss.notes}
                  </div>
                )}
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1">
                We therefore believe that the most appropriate risk capacity
                category for you is:
              </div>
              <div
                className="sbrBlueSubSubHeading"
                style={{ color: checkboxColour }}
              >
                {
                  getIsClient(
                    _initConfig.sBRConfig[sbrConfigIndex].riskCapacityCategories
                  )[state.clientCapacityForLoss.capacityForLossScore].text
                }
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1">
                {getIsClient(
                  _initConfig.sBRConfig[sbrConfigIndex].riskCapacityCategories
                )[
                  state.clientCapacityForLoss.capacityForLossScore
                ].reportParagraphs.map((item) => {
                  return (
                    <div className="recommendationsParagraph recommendationsParagraph1">
                      {item.paragraph}
                    </div>
                  );
                })}
              </div>
              <div
                className="sbrBlueSubSubHeading"
                style={{ color: checkboxColour }}
              >
                Our discussion
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1">
                {state.clientCapacityForLoss.clientCommentsOnCFL}
              </div>
              {/* YOUR FINANCIAL PLAN AND RECOMMENDATIONS */}
              <div className="sbrSubheading" style={{ color: nextColour }}>
                Your financial plan and recommendations
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1">
                We’ve based our recommendations for you on your financial goals,
                to give you the best chance of reaching them.
              </div>
              {/* YOUR GOALS */}
              <div className="sbrSubheading" style={{ color: nextColour }}>
                Your goals
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1">
                We established that you have the following financial goals:
              </div>
              <div className="sbrGoals">
                {state.goals.slice(0, 3).map((goal) => (
                  <div>
                    <AmmoniteReportGoalCard
                      inflation={
                        context.state.nonClientData.Assumptions.inflation
                      }
                      inflationLinked={goal.inflationLinked}
                      goalType={goal.goalType}
                      goalName={goal.goalName}
                      amountRequiredToday={goal.amountRequiredToday}
                      customer1DoB={state.people[0].dob || state.people[1].dob}
                      goalTimeHorizon={goal.goalTimeHorizon}
                    />
                  </div>
                ))}
              </div>
              <div className="sbrGoals">
                {state.goals.slice(3, 6).map((goal) => (
                  <div>
                    <AmmoniteReportGoalCard
                      inflation={
                        context.state.nonClientData.Assumptions.inflation
                      }
                      inflationLinked={goal.inflationLinked}
                      goalType={goal.goalType}
                      goalName={goal.goalName}
                      amountRequiredToday={goal.amountRequiredToday}
                      customer1DoB={state.people[0].dob || state.people[1].dob}
                      goalTimeHorizon={goal.goalTimeHorizon}
                    />
                  </div>
                ))}
              </div>
              <div className="italicText">
                *inflation-linked, where appropriate, assuming inflation at{" "}
                {context.state.nonClientData.Assumptions.inflation * 100}%.
                Please note that inflation is subject to change, and the amount
                you may need in tomorrow’s money may be more or less than shown
                above.
              </div>
              <div className="sbrSubheading" style={{ color: nextColour }}>
                Goal focus for now
              </div>
              {state.goals &&
                getIsNotEveryGoalFocused(state.goals) &&
                state.goals.length > 1 && (
                  <div className="recommendationsParagraph recommendationsParagraph1">
                    We think it's important to get you on your way towards your
                    most important goals first. Because of that, we’ve focused
                    on these goals in this report. Once you’re on your way
                    towards these goals, we’ll help you on your way to achieving
                    your other goals too.
                  </div>
                )}
              {/* RETIREMENT SPENDING - PRIMARY */}
              <div
                className="sbrBlueSubSubHeading spacingSmall"
                style={{ color: checkboxColour }}
              >
                Retirement Spending - Primary
              </div>
              {status === "success" && state.goals[0] && (
                <div className="recommendationsParagraph recommendationsParagraph1">
                  You're targeting annual spending in retirement of £
                  {formatNumbers(state.annualRetirementSpending)
                    ? formatNumbers(state.annualRetirementSpending)
                    : 0}
                  . That means you might need a retirement pot of around £
                  {state.goals[0].amountRequiredToday
                    ? formatNumbers(
                        getInflationOnRetirementGoal(
                          state.goals[0].amountRequiredToday,
                          state.goals[0].goalTimeHorizon,
                          state.people[0].dob || state.people[1].dob,
                          context.state.nonClientData.Assumptions.inflation
                        )
                      )
                    : 0}{" "}
                  in tomorrow's money (i.e. after inflation), assuming a
                  withdrawal rate of 3.5% and inflation at{" "}
                  {context.state.nonClientData.Assumptions.inflation * 100}%.
                  <div className="recommendationsParagraph recommendationsParagraph1">
                    {state.goals[0].goalNotes ? state.goals[0].goalNotes : ""}
                  </div>
                </div>
              )}
              {status === "success" &&
                getGoalRankedByImportance(state.goals).map((goal) => (
                  <div>
                    {goal.focused === true && (
                      <div>
                        <div
                          className="sbrBlueSubSubHeading"
                          style={{ color: checkboxColour }}
                        >
                          {goal.goalName} -{" "}
                          {getGoalImportance(parseInt(goal.goalImportance))}
                        </div>
                        <div className="recommendationsParagraph recommendationsParagraph1">
                          {goal.goalNotes ? goal.goalNotes : ""}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              {status === "success" &&
                getDoesGoalFocusExist(state.goals) &&
                state.goals &&
                getIsNotEveryGoalFocused(state.goals) &&
                getGoalRankedByImportance(state.goals)
                  .filter(
                    (obj) => obj.focused === false || obj.focused === undefined
                  )
                  .map((goal, index) => (
                    <div>
                      {index === 0 && (
                        <div
                          className="sbrSubheading"
                          style={{ color: nextColour }}
                        >
                          Goal focus for later
                        </div>
                      )}
                      <div>
                        <div
                          className="sbrBlueSubSubHeading"
                          style={{ color: checkboxColour }}
                        >
                          {goal.goalName} -{" "}
                          {getGoalImportance(parseInt(goal.goalImportance))}
                        </div>
                        <div className="recommendationsParagraph recommendationsParagraph1">
                          {goal.goalNotes ? goal.goalNotes : ""}
                        </div>
                      </div>
                    </div>
                  ))}
              {/* HOW WE RECOMMEND YOU REACH YOUR GOALS */}
              <div className="sbrSubheading" style={{ color: nextColour }}>
                How we recommend you reach your goals
              </div>
              {status === "success" &&
                getContributionTypeExists(
                  "getIsInvestmentLumpSum",
                  state.newPensionsAdvice,
                  state.newISAsAdvice,
                  state.newGIAsAdvice,
                  state.newCashAdvice
                ) && (
                  <div>
                    <div
                      className="sbrBlueSubSubHeading recommendationsParagraph"
                      style={{ color: checkboxColour }}
                    >
                      Invest for long term goals
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1">
                      If inflation exceeds interest rates, cash holdings will
                      lose value against inflation over the long term.
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1">
                      We think its important to benefit from investment growth,
                      to grow your pots and help you reach your goals. It’s also
                      important to put your money in the right ‘tax wrapper’, so
                      that you can benefit from tax reliefs available. We
                      recommend the following lump sum contributions:
                    </div>
                  </div>
                )}
              {status === "success" &&
                getContributionTypeExists(
                  "getInvestmentsWithLumpSum",
                  state.newPensionsAdvice,
                  state.newISAsAdvice,
                  state.newGIAsAdvice,
                  state.newCashAdvice
                ).length > 0 &&
                getContributionTypeExists(
                  "getInvestmentsWithLumpSum",
                  state.newPensionsAdvice,
                  state.newISAsAdvice,
                  state.newGIAsAdvice,
                  state.newCashAdvice
                ).map((investment) => (
                  <div className="investmentSummaryRow">
                    <AmmoniteInvestmentSummary
                      who={getOwners(investment)}
                      wrapper={
                        investment.type === "Cash advice"
                          ? "Cash Account"
                          : investment.type.charAt(0).toUpperCase() +
                            investment.type.slice(1)
                      }
                      contributionAmount={getWrapperContributionAmount(
                        investment,
                        "LumpSum"
                      )}
                      isPensionRecommendation={true}
                      isAdviceSummary={true}
                    />
                  </div>
                ))}
              {status === "success" &&
                getContributionTypeExists(
                  "getIsMonthly",
                  state.newPensionsAdvice,
                  state.newISAsAdvice,
                  state.newGIAsAdvice,
                  state.newCashAdvice
                ) && (
                  <div>
                    <div
                      className="sbrBlueSubSubHeading recommendationsParagraph"
                      style={{ color: checkboxColour }}
                    >
                      Start making smart contributions
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1">
                      We’ve used your agreed budget to make our recommendations
                      for your regular monthly contributions. Setting up monthly
                      contributions is a great start. By contributing on a
                      regular basis you can benefit from what’s called ‘pound
                      cost averaging’, which means that you’ll benefit as
                      investments go up and down (since when investments go down
                      you’ll be buying at a lower cost). This can really help
                      with long term investing.
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1">
                      We recommend the following monthly contributions:
                    </div>
                  </div>
                )}
              {status === "success" &&
                getContributionTypeExists(
                  "getInvestmentsWithMonthly",
                  state.newPensionsAdvice,
                  state.newISAsAdvice,
                  state.newGIAsAdvice,
                  state.newCashAdvice
                ).length > 0 &&
                getContributionTypeExists(
                  "getInvestmentsWithMonthly",
                  state.newPensionsAdvice,
                  state.newISAsAdvice,
                  state.newGIAsAdvice,
                  state.newCashAdvice
                ).map((investment) => (
                  <div className="investmentSummaryRow">
                    <AmmoniteInvestmentSummary
                      who={getOwners(investment)}
                      wrapper={
                        investment.type === "Cash advice"
                          ? "Cash Account"
                          : investment.type.charAt(0).toUpperCase() +
                            investment.type.slice(1)
                      }
                      contributionAmount={getWrapperContributionAmount(
                        investment,
                        "Monthly"
                      )}
                      isPensionRecommendation={true}
                      isAdviceSummary={true}
                    />
                  </div>
                ))}
              {/* TAKE CONTROL OF YOUR PENSIONS */}
              {status === "success" && state.pensions.length > 0 && (
                <div>
                  <div
                    className="sbrBlueSubSubHeading recommendationsParagraph"
                    style={{ color: checkboxColour }}
                  >
                    Take control of your pensions
                  </div>
                  <div className="recommendationsParagraph recommendationsParagraph1">
                    Pensions are the most powerful tool to plan for your
                    retirement. It’s really important to make sure that your
                    pensions are working as hard for you as possible, and that
                    when you come to retirement you’ll be able to use them in
                    the way you want to. Some pensions are restrictive in the
                    way you can draw money from them, the investments might not
                    be in line with your risk profile, they may have high
                    charges etc. We’ve looked at all of the features of those
                    pensions you asked us to research, and recommend the
                    following (our full analysis of the pensions is detailed
                    later in this report).{" "}
                  </div>
                  <div className="investmentSummaryRow">
                    {status === "success" &&
                      state.pensions.map((pension) => {
                        return (
                          <AmmoniteInvestmentSummary
                            who={getOwners(pension)}
                            provider={pension.provider}
                            policyNumber={pension.policyNumber}
                            recommendation={pension.transferPension}
                            isPensionRecommendation={true}
                            isAdviceSummary={false}
                          />
                        );
                      })}
                  </div>
                </div>
              )}
              {/* FOCUS ON YOUR INVESTMENTS */}
              {status === "success" && state.investments.length > 0 && (
                <div>
                  <div
                    className="sbrBlueSubSubHeading recommendationsParagraph"
                    style={{ color: checkboxColour }}
                  >
                    Focus on your investments
                  </div>
                  <div className="recommendationsParagraph recommendationsParagraph1">
                    It's important to make sure that you invest in line with
                    your goals, to give you the best chance of reaching them.
                    We've looked at your existing investment holdings, and
                    recommend the following:
                  </div>
                  <div className="investmentSummaryRow">
                    {status === "success" &&
                      state.investments.map((investment) => {
                        return (
                          <AmmoniteInvestmentSummary
                            who={getOwners(investment)}
                            provider={investment.provider}
                            policyNumber={investment.policyNumber}
                            recommendation={{
                              transfer: investment.transferISA,
                              transferGIA: investment.transferGIA,
                            }}
                            isInvestmentRecommendation={true}
                          />
                        );
                      })}
                  </div>
                </div>
              )}
              {/* RECOMMENDED PROVIDER AND INVESTMENT SOLUTION */}
              <div
                className="sbrBlueSubSubHeading        recommendationsParagraph"
                style={{ color: checkboxColour }}
              >
                Use our recommended provider and investment solution
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1">
                We’ve looked at the whole of the market, so that you don’t have
                to. It’s important to make sure that your providers and
                investment solutions work for you. That means choosing providers
                that have good administration and are cost-efficient, with
                user-friendly online tools and great reporting. The investment
                solutions need to be right for your goals and risk profile, with
                good diversification and low charges.
              </div>
              {status === "success" &&
                (state.newPensionsAdvice.length > 0 ||
                  state.newISAsAdvice.length > 0 ||
                  state.newGIAsAdvice.length > 0 ||
                  state.newCashAdvice.length > 0) && (
                  <div className="recommendationsParagraph recommendationsParagraph1">
                    Based on these considerations, we’ve chosen the following
                    solutions for you:
                  </div>
                )}
              {status === "success" &&
                state.newPensionsAdvice &&
                state.newISAsAdvice &&
                state.newGIAsAdvice &&
                getAllInvestmentSolutionsDeduped(
                  state.newPensionsAdvice,
                  state.newISAsAdvice,
                  state.newGIAsAdvice,
                  state.clientAttitudeToRisk.attitudeToRiskScore,
                  getIsClient(
                    _initConfig.sBRConfig[sbrConfigIndex].riskCategories
                  )["YEARS TO GOAL"],
                  getIsClient(
                    _initConfig.sBRConfig[sbrConfigIndex].investmentStrategy
                  )
                ).map((solution) => (
                  <div>
                    <div className="investmentSummaryRow">
                      <AmmoniteInvestmentSummary
                        provider={solution.provider}
                        investmentSolution={solution.investmentSolution}
                        isAdviceSummary
                      />
                    </div>
                  </div>
                ))}
              <div className="recommendationsParagraph recommendationsParagraph1">
                For further details on why we recommend these solutions, please
                see the relevant sections below.
              </div>
              <div
                className="sbrBlueSubSubHeading       recommendationsParagraph"
                style={{ color: checkboxColour }}
              >
                Will I reach my goals?
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1">
                We can’t guarantee that you’ll reach your goals, but our
                recommendations are designed to put you on the best path to
                getting there. If you’re able to increase your contributions in
                the future, that might help you reach your goals faster.
              </div>
              <div className="sbrSubheading" style={{ color: nextColour }}>
                Your portfolio after our recommendations
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1">
                After you implement our recommendations, your portfolio will be
                made up of the following policies (excluding any policies you
                didn’t ask us to research for you, since they fall outside our
                advice):
              </div>
              {/* PENSIONS */}
              {status === "success" &&
                getInvestmentSolutionsGroupedByGoals(
                  state.pensions,
                  state.investments,
                  state.newPensionsAdvice,
                  state.newISAsAdvice,
                  state.newGIAsAdvice,
                  state.clientAttitudeToRisk.attitudeToRiskScore,
                  getIsClient(
                    _initConfig.sBRConfig[sbrConfigIndex].riskCategories
                  )["YEARS TO GOAL"],
                  getIsClient(
                    _initConfig.sBRConfig[sbrConfigIndex].investmentStrategy
                  ),
                  state.goals,
                  sbrConfigIndex
                ).map((solution) =>
                  solution && solution.type ? (
                    <div className="investmentSummaryRow">
                      <AmmoniteInvestmentAfterRecommendations
                        owner={getOwners(solution)}
                        wrapper={getCapitalisedType(solution.type)}
                        type={solution.type}
                        provider={solution.provider}
                        investments={solution.investmentSolutions}
                        totalInitialAmount={solution.totalInitialAmount}
                        monthlyContributions={solution.monthlyContributions}
                        goalsAttributed={solution.goalsAttributedProperties}
                      />
                    </div>
                  ) : (
                    <div className="investmentSummaryRow"></div>
                  )
                )}
              {status === "success" &&
                state.newCashAdvice &&
                getCashSolutionsGroupedByGoals(
                  state.newCashAdvice,
                  state.clientAttitudeToRisk.attitudeToRiskScore,
                  getIsClient(
                    _initConfig.sBRConfig[sbrConfigIndex].riskCategories
                  )["YEARS TO GOAL"],
                  getIsClient(
                    _initConfig.sBRConfig[sbrConfigIndex].investmentStrategy
                  )
                ).map((solution) => (
                  <div className="investmentSummaryRow">
                    <AmmoniteInvestmentAfterRecommendations
                      owner={getOwners(solution)}
                      wrapper={solution.type}
                      provider="-"
                      investments={solution.investmentSolutions}
                      totalInitialAmount={solution.totalInitialAmount}
                      monthlyContributions={solution.monthlyContributions}
                      // goalsAttributed={solution.goalsAttributedProperties}
                    />
                  </div>
                ))}
              {/* WHY WE MADE THESE RECOMMENDATIONS */}
              <div
                className="sbrSubheading spacingLarge"
                style={{ color: nextColour }}
              >
                Why we've made these recommendations
              </div>
              {status === "success" &&
                (areTherePensionContributions(state.newPensionsAdvice) ||
                  areThereOtherInvestmentContributions(
                    state.newISAsAdvice,
                    state.newGIAsAdvice
                  )) && (
                  <div>
                    <div
                      className="sbrBlueSubSubHeading spacingSmall"
                      style={{ color: checkboxColour }}
                    >
                      Why these contribution amounts?
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1">
                      We discussed your budget and the amount you could invest
                      as a lump sum contribution towards your goals, and based
                      on our recommendations on this.
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1">
                      In terms of the split between the different wrappers (i.e.
                      ISA / Pension / Cash / General Investment Account), we’ve
                      used our tools to come up with the best plan for you. We
                      take into account the goal amounts that you’re trying to
                      achieve, and when you’re aiming to get there. We also take
                      into consideration your risk tolerance and time horizon
                      for each goal. Additionally, we take government tax
                      allowances into account, because we want you to be able to
                      benefit from these as much as possible.
                    </div>
                  </div>
                )}

              {status === "success" &&
                areTherePensionContributions(state.newPensionsAdvice) && (
                  <div>
                    <div
                      className="sbrBlueSubSubHeading"
                      style={{ color: checkboxColour }}
                    >
                      Your pension contributions
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1">
                      Pensions are a very tax-efficient way of planning for
                      retirement, since contributions benefit from tax relief at
                      your marginal rate of tax. Personal contributions to a
                      registered pension scheme receive basic rate tax relief at
                      source. This means for every £80 paid in, the pension fund
                      will receive an additional £20 in tax relief. Higher and
                      additional rate taxpayers can claim further tax relief
                      through their tax return.
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1">
                      Tax relief on personal gross contributions is restricted
                      to the higher of £3,600 or 100% of your ‘relevant UK
                      earnings’ each year and is capped at an annual allowance
                      of £40,000 (unused allowances can be carried forward from
                      the previous three tax years). If your income is over
                      £240,000, your annual allowance is restricted.
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1">
                      Based on your income and contribution history, you have
                      the scope to make the recommended contributions without
                      exceeding your annual allowance.
                    </div>
                    {getAnyEmployerContributions(state.newPensionsAdvice) && (
                      <div className="recommendationsParagraph recommendationsParagraph1">
                        {uniqueOwners.length > 1
                          ? uniqueOwners.join(" and ")
                          : uniqueOwners}
                        , we recommend that you make contributions as employer
                        contributions from your limited company. Your limited
                        company can contribute pre-taxed company income to your
                        pension. Because an employer contribution counts as an
                        allowable business expense, your company receives tax
                        relief against corporation tax, so the company could
                        save up to 19% in corporation tax.
                        <div className="recommendationsParagraph recommendationsParagraph1">
                          Another benefit is that employers don’t have to pay
                          National Insurance on pension contributions, so by
                          contributing directly into your pension rather than
                          paying the equivalent in salary, you save up to 13.8%.
                        </div>
                        <div className="recommendationsParagraph recommendationsParagraph1">
                          This means that in total, your company can save up to
                          32.8% by paying money directly into your pension
                          rather than paying money in the form of a salary.
                        </div>
                      </div>
                    )}
                  </div>
                )}
              {status === "success" && state.newPensionsAdvice.length > 0 && (
                <div>
                  <div
                    className="sbrBlueSubSubHeading"
                    style={{ color: checkboxColour }}
                  >
                    Why these ‘wrappers’?
                  </div>
                  <div className="recommendationsParagraph recommendationsParagraph1">
                    <strong>Pension portfolio</strong> - Not only do pensions
                    benefit from tax relief on contributions, but they also
                    benefit from tax-free growth within the pension. That means
                    that the capital growth and dividends you may receive within
                    the pension are tax-free. This makes pensions a great tax
                    wrapper. However, there are rules about when you can access
                    the money in your pension. Unless you’re retiring early
                    because of ill health, the earliest you can currently draw
                    money from your pension is age 55. We’ve taken this into
                    account in our recommendations.
                  </div>
                  <div className="recommendationsParagraph recommendationsParagraph1 ">
                    We think it’s important to use a self-invested personal
                    pension rather than transferring into an employer scheme,
                    since you have more control over a personal pension, and to
                    keep consistency even if you were to change employment in
                    the future. You will also have a wider scope of investment
                    opportunities available to you, including our recommended
                    investments.
                  </div>
                  <div className="recommendationsParagraph recommendationsParagraph1 ">
                    We’ve considered whether you should use a ‘Stakeholder
                    pension’, however we’ve discounted this because our
                    recommended pension is cheaper, and offers our recommended
                    investment solution.
                  </div>
                  <div className="recommendationsParagraph recommendationsParagraph1 ">
                    We've recommended a pension that offers 'flexi access
                    drawdown'. Flexi-access drawdown is a pension product that
                    lets you access your pension savings whenever you need to in
                    retirement, while reinvesting your remaining funds in a way
                    that’s designed to provide an ongoing retirement income.
                    Flexi-access drawdown allows you to withdraw as much or as
                    little retirement income as you wish, while choosing how the
                    remainder of your fund is invested. As part of the
                    wide-ranging 2015 pension reforms, which allow those nearing
                    retirement to gain greater control over their pensions,
                    flexi-access drawdown is available from your 55th birthday
                    and provides a hassle-free way to unlock cash from your
                    pension. With flexi-access drawdown you can take up to 25%
                    of your pension tax-free, as a lump sum or in portions.
                    Whether you intend to use it to supplement your income, to
                    help loved ones or fulfil a lifelong dream, it can be yours
                    to spend however you wish.
                  </div>
                  <div className="recommendationsParagraph recommendationsParagraph1 ">
                    Once you’ve taken your tax-free lump sum, the rest of your
                    pension pot can be left invested. This offers the
                    opportunity for growth, unlike an annuity which provides a
                    fixed income.
                  </div>
                  <div className="recommendationsParagraph recommendationsParagraph1 ">
                    If you decide to draw a regular income it’s possible to
                    manage the amount you want, and at your desired intervals.
                    If you wish, this can be adjusted periodically to help fit
                    your circumstances and can save you income tax with some
                    clever planning.
                  </div>
                  <div className="recommendationsParagraph recommendationsParagraph1 ">
                    Should you pass away before releasing all of your pension,
                    you can nominate someone to receive the remaining funds on
                    your behalf - such as a relative or charity. On death before
                    age 75, they will inherit the pot tax-free. On death after
                    age 75, it's paid out subject to their marginal rate of
                    income tax.
                  </div>
                </div>
              )}
              {status === "success" && state.newISAsAdvice.length > 0 && (
                <div className="recommendationsParagraph recommendationsParagraph1 ">
                  <strong>ISA</strong> - Individual Savings Accounts, or ‘ISAs’,
                  are a good wrapper to invest in, since investments within an
                  ISA are free from income and capital gains tax. Not only that,
                  but subject to restrictions on the investments themselves, you
                  can access the money within your ISA without restriction. You
                  can therefore use your ISA for those goals that you’ll need
                  before retirement. The ISA allowance is currently £20,000 per
                  person, per year. Our recommendations are based on what you’ve
                  told us about your ISA contributions, and are designed to
                  maximise your ISA contributions while taking into account your
                  budget and pension contributions.
                </div>
              )}
              {status === "success" && state.newGIAsAdvice.length > 0 && (
                <div className="recommendationsParagraph recommendationsParagraph1 ">
                  <strong>GIA</strong> - A general investment account, or 'GIA'
                  is a simple way of investing more money once you've reached
                  your annual ISA allowance. Unlike an ISA or pension, there are
                  no tax benefits, although you can contribute as much as you
                  wish. Like an ISA, you can access the money without
                  restriction. You pay income tax on any income you receive from
                  the GIA, and capital gains tax on any realised gain you make
                  on your GIA. The tax paid will depend on your personal tax
                  situation. For further details on the taxation of GIAs, please
                  see the 'Key Features' document.
                </div>
              )}
              {status === "success" && state.newCashAdvice.length > 0 && (
                <div className="recommendationsParagraph recommendationsParagraph1 ">
                  <strong>Cash</strong> - For shorter term goals, we recommend
                  keeping money in a cash account, to protect against the
                  investment risks of your money going up and down over the
                  short time horizon (though please note that we don't provide
                  advice on which cash account to use).
                </div>
              )}
              {status === "success" &&
                getFundProviders(
                  state.newPensionsAdvice ? state.newPensionsAdvice : [],
                  state.newISAsAdvice ? state.newISAsAdvice : [],
                  state.newGIAsAdvice ? state.newGIAsAdvice : [],
                  state.clientAttitudeToRisk.attitudeToRiskScore,
                  getIsClient(
                    _initConfig.sBRConfig[sbrConfigIndex].riskCategories
                  )["YEARS TO GOAL"],
                  getIsClient(
                    _initConfig.sBRConfig[sbrConfigIndex].investmentStrategy
                  ),
                  getIsClient(
                    _initConfig.sBRConfig[sbrConfigIndex].providerParagraphs
                  ),
                  true
                ).map((provider) => {
                  return (
                    <div>
                      <div
                        className="sbrBlueSubSubHeading"
                        style={{ color: checkboxColour }}
                      >
                        Why {provider.text}?
                      </div>
                      {provider.reportParagraphs.map((para) => {
                        return (
                          <div className="recommendationsParagraph recommendationsParagraph1 ">
                            {para.paragraph}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              {status === "success" &&
                getFundProviders(
                  state.newPensionsAdvice ? state.newPensionsAdvice : [],
                  state.newISAsAdvice ? state.newISAsAdvice : [],
                  state.newGIAsAdvice ? state.newGIAsAdvice : [],
                  state.clientAttitudeToRisk.attitudeToRiskScore,
                  getIsClient(
                    _initConfig.sBRConfig[sbrConfigIndex].riskCategories
                  )["YEARS TO GOAL"],
                  getIsClient(
                    _initConfig.sBRConfig[sbrConfigIndex].investmentStrategy
                  ),
                  getIsClient(
                    _initConfig.sBRConfig[sbrConfigIndex]
                      .investmentSolutionParagraphs
                  ),
                  false
                ).map((provider) => {
                  return (
                    <div>
                      <div
                        className="sbrBlueSubSubHeading"
                        style={{ color: checkboxColour }}
                      >
                        Why {provider.text}?
                      </div>
                      {provider.reportParagraphs.map((para) => {
                        return (
                          <div className="recommendationsParagraph recommendationsParagraph1 ">
                            {para.paragraph}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              {state.goals.length > 0 && (
                <div
                  className="sbrBlueSubSubHeading"
                  style={{ color: checkboxColour }}
                >
                  How do the investments relate to my goals?
                </div>
              )}

              {status === "success" &&
                getIsClient(
                  _initConfig.sBRConfig[sbrConfigIndex].investmentGoalText
                ) &&
                getInvestmentSolutionsGroupedByGoalsRisk(
                  state.newPensionsAdvice,
                  state.newISAsAdvice,
                  state.newGIAsAdvice,
                  state.clientAttitudeToRisk.attitudeToRiskScore,
                  getIsClient(
                    _initConfig.sBRConfig[sbrConfigIndex].riskCategories
                  )["YEARS TO GOAL"],
                  getIsClient(
                    _initConfig.sBRConfig[sbrConfigIndex].investmentStrategy
                  ),
                  state.goals
                ).map((solution) => {
                  return (
                    <AmmoniteInvestmentGoalRiskLevels
                      investment={solution.investmentSolution}
                      equity={solution.equity}
                      goalRisk={solution.goalRisk}
                      goals={[solution.goalsAttributed[0]]}
                      goalType={solution.goalType}
                      goalName={solution.goalName}
                      investmentGoalText={getIsClient(
                        _initConfig.sBRConfig[sbrConfigIndex].investmentGoalText
                      )}
                    />
                  );
                })}
              {/* WHAT ARE THE CHARGES */}
              <div className="sbrSubheading" style={{ color: nextColour }}>
                What are the charges?
              </div>
              {getIsClient(
                _initConfig.sBRConfig[sbrConfigIndex].chargesIntroductionText
              ).map((item) => {
                return (
                  <div className="recommendationsParagraph recommendationsParagraph1 ">
                    {item.paragraph}
                  </div>
                );
              })}
              {status === "success" &&
                state.newISAsAdvice &&
                state.newGIAsAdvice &&
                _initConfig && (
                  <div className="investmentSummaryRow">
                    <div className="investmentSummaryRow">
                      <AmmoniteCharge
                        chargeName="ISA / General Investment Account:"
                        paRate={getISAGIACharges(
                          state.newISAsAdvice,
                          state.newGIAsAdvice,
                          false,
                          state.clientAttitudeToRisk.attitudeToRiskScore,
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex].riskCategories
                          )["YEARS TO GOAL"],
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex]
                              .investmentStrategy
                          ),
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex]
                              .providerCharges
                          )
                        ).toFixed(2)}
                      />
                    </div>
                    <div className="investmentSummaryRow">
                      <AmmoniteCharge
                        chargeName="Pension:"
                        paRate={getPensionCharges(
                          state.newPensionsAdvice,
                          false,
                          state.clientAttitudeToRisk.attitudeToRiskScore,
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex].riskCategories
                          )["YEARS TO GOAL"],
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex]
                              .investmentStrategy
                          ),
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex]
                              .providerCharges
                          )
                        ).toFixed(2)}
                      />
                    </div>
                    <div className="investmentSummaryRow">
                      {getISAGIACharges(
                        state.newISAsAdvice,
                        state.newGIAsAdvice,
                        "discretionary",
                        state.clientAttitudeToRisk.attitudeToRiskScore,
                        getIsClient(
                          _initConfig.sBRConfig[sbrConfigIndex].riskCategories
                        )["YEARS TO GOAL"],
                        getIsClient(
                          _initConfig.sBRConfig[sbrConfigIndex]
                            .investmentStrategy
                        ),
                        getIsClient(
                          _initConfig.sBRConfig[sbrConfigIndex].providerCharges
                        )
                      ) === true && (
                        <AmmoniteCharge
                          chargeName="Discretionary Fund Management:"
                          discretionaryCharge={0.9}
                        />
                      )}
                    </div>
                    <div className="investmentSummaryRow">
                      <AmmoniteCharge
                        chargeName="Underlying Investments:"
                        paRate={getISAGIAPensionCharges(
                          state.newPensionsAdvice,
                          state.newISAsAdvice,
                          state.newGIAsAdvice,
                          "providers",
                          state.clientAttitudeToRisk.attitudeToRiskScore,
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex].riskCategories
                          )["YEARS TO GOAL"],
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex]
                              .investmentStrategy
                          ),
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex]
                              .providerCharges
                          )
                        )}
                      />
                    </div>
                    <div className="investmentSummaryRow">
                      <AmmoniteCharge
                        chargeName="Our ongoing service:"
                        paRate={getAdviserCharges(
                          state.newPensionsAdvice,
                          state.newISAsAdvice,
                          state.newGIAsAdvice,
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex][
                              "adviserCharges"
                            ]
                          )
                        ).toFixed(2)}
                      />
                    </div>
                    <div className="investmentSummaryRow">
                      <AmmoniteCharge
                        chargeName="Total £ charge:"
                        totalPoundCharge={getTotalPoundCharge(
                          state.newPensionsAdvice,
                          state.newISAsAdvice,
                          state.newGIAsAdvice,
                          state.clientAttitudeToRisk.attitudeToRiskScore,
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex].riskCategories
                          )["YEARS TO GOAL"],
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex]
                              .investmentStrategy
                          ),
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex]
                              .providerCharges
                          ),
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex][
                              "adviserCharges"
                            ]
                          )
                        )}
                      />
                    </div>
                    <div className="investmentSummaryRow">
                      <AmmoniteCharge
                        chargeName="Total weighted charge:"
                        paRate={getTotalWeightedCharge(
                          state.newPensionsAdvice,
                          state.newISAsAdvice,
                          state.newGIAsAdvice,
                          state.clientAttitudeToRisk.attitudeToRiskScore,
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex].riskCategories
                          )["YEARS TO GOAL"],
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex]
                              .investmentStrategy
                          ),
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex]
                              .providerCharges
                          ),
                          getIsClient(
                            _initConfig.sBRConfig[sbrConfigIndex][
                              "adviserCharges"
                            ]
                          )
                        ).toFixed(2)}
                      />
                    </div>
                    {getISAGIACharges(
                      state.newISAsAdvice,
                      state.newGIAsAdvice,
                      "discretionary",
                      state.clientAttitudeToRisk.attitudeToRiskScore,
                      getIsClient(
                        _initConfig.sBRConfig[sbrConfigIndex].riskCategories
                      )["YEARS TO GOAL"],
                      getIsClient(
                        _initConfig.sBRConfig[sbrConfigIndex].investmentStrategy
                      ),
                      getIsClient(
                        _initConfig.sBRConfig[sbrConfigIndex].providerCharges
                      )
                    ) === true && (
                      <div className="recommendationsParagraph recommendationsParagraph1 ">
                        Please note that, within your Cazenove discretionary
                        managed portfolio, there are additional charges for the
                        underlying holdings, which will vary. For full details
                        of the underlying charges, please see your investment
                        proposal.
                      </div>
                    )}
                    {getIsClient(
                      _initConfig.sBRConfig[sbrConfigIndex].chargesText
                    ).map((item) => {
                      return (
                        <div className="recommendationsParagraph recommendationsParagraph1 ">
                          {item.paragraph}
                        </div>
                      );
                    })}
                    <div className="investmentSummaryRow">
                      {getIsClient(
                        _initConfig.sBRConfig[sbrConfigIndex].adviserCharges
                      ).totalImplementationCharge && (
                        <AmmoniteCharge
                          chargeName="Total Initial implementation charge:"
                          NoPaRate={getTotalImplementationCharge(
                            state.newPensionsAdvice,
                            state.newISAsAdvice,
                            state.newGIAsAdvice,
                            getIsClient(
                              _initConfig.sBRConfig[sbrConfigIndex]
                                .adviserCharges
                            ).totalImplementationCharge
                          ).toFixed(2)}
                          totalPot={getTotalPortfolioValue(
                            state.newPensionsAdvice,
                            state.newISAsAdvice,
                            state.newGIAsAdvice
                          )}
                          totalCharge={getTotalImplementationChargeOnPot(
                            state.newPensionsAdvice,
                            state.newISAsAdvice,
                            state.newGIAsAdvice,
                            getIsClient(
                              _initConfig.sBRConfig[sbrConfigIndex]
                                .adviserCharges
                            ).totalImplementationCharge
                          )}
                          bandChargeMinimum={
                            getIsClient(
                              _initConfig.sBRConfig[sbrConfigIndex]
                                .adviserCharges
                            ).totalImplementationCharge.bandChargeMinimum
                          }
                        />
                      )}
                    </div>
                    {state.pensions.length > 0 && (
                      <div>
                        <div
                          className="sbrSubheading"
                          style={{ color: nextColour }}
                        >
                          Your existing pension plans
                        </div>
                        <div className="recommendationsParagraph recommendationsParagraph1 ">
                          We looked closely at your existing policies, and have
                          done a full analysis which you’ll find below,
                          including our reasons for recommending that you retain
                          or switch the policy.
                        </div>
                      </div>
                    )}
                    {status === "success" &&
                      state.newPensionsAdvice &&
                      state.pensions.map((pension, index) => (
                        <AmmoniteExistingPension
                          checkboxColour={checkboxColour}
                          owner={getOwners(pension)}
                          provider={pension.provider ? pension.provider : "-"}
                          policyNumber={
                            pension.policyNumber ? pension.policyNumber : "-"
                          }
                          fundValue={
                            pension.fundValue ? pension.fundValue : "-"
                          }
                          transferValue={
                            pension.transferValue ? pension.transferValue : "-"
                          }
                          retirementDate={pension.srdNrd ? pension.srdNrd : "-"}
                          investmentChoice={
                            pension.investments ? pension.investments : "-"
                          }
                          penaltiesOnTransfer={
                            parseInt(pension.penaltiesOnTransfer)
                              ? parseInt(pension.penaltiesOnTransfer)
                              : "-"
                          }
                          protectedTaxFreeCash={
                            pension.protectedTaxFreeCash
                              ? pension.protectedTaxFreeCash
                              : "-"
                          }
                          guarantees={
                            pension.guaranteeDetails
                              ? pension.guaranteeDetails
                              : "-"
                          }
                          oorGuarantees={pension.oorGuarantees}
                          recommendation={
                            pension.transferPension ? "TRANSFER" : "RETAIN"
                          }
                          annuityPurchase={
                            pension.annuityPurchase
                              ? pension.annuityPurchase
                              : "-"
                          }
                          uFPLS={pension.uFPLS ? pension.uFPLS : "-"}
                          flexiAccess={
                            pension.flexiAccess ? pension.flexiAccess : "-"
                          }
                          drawdown={pension.drawdown ? pension.drawdown : "-"}
                          charges={pension.charges ? pension.charges : "-"}
                          expressionOfWish={
                            pension.expressionOfWish
                              ? pension.expressionOfWish
                              : "-"
                          }
                          recommendedCharges={
                            getRecommendedPolicy(
                              state.newPensionsAdvice,
                              state.clientAttitudeToRisk.attitudeToRiskScore,
                              getIsClient(
                                _initConfig.sBRConfig[sbrConfigIndex]
                                  .riskCategories
                              )["YEARS TO GOAL"],
                              getIsClient(
                                _initConfig.sBRConfig[sbrConfigIndex]
                                  .investmentStrategy
                              ),
                              getIsClient(
                                _initConfig.sBRConfig[sbrConfigIndex]
                                  .providerCharges
                              )
                            ) || "-"
                          }
                        />
                      ))}
                    {state.investments.length > 0 && (
                      <div>
                        <div
                          className="sbrSubheading"
                          style={{ color: nextColour }}
                        >
                          Your existing investment plans
                        </div>
                        <div className="recommendationsParagraph recommendationsParagraph1 ">
                          We looked closely at your existing investment
                          policies, and have done a full analysis which you’ll
                          find below, including our reasons for recommending
                          that you retain or transfer the policy.
                        </div>
                      </div>
                    )}
                    {status === "success" &&
                      state.investments.map((investment, index) => (
                        <AmmoniteExistingInvestment
                          checkboxColour={checkboxColour}
                          owner={getOwners(investment)}
                          provider={
                            investment.provider ? investment.provider : "-"
                          }
                          policyNumber={
                            investment.policyNumber
                              ? investment.policyNumber
                              : "-"
                          }
                          planType={
                            investment.planType ? investment.planType : "-"
                          }
                          value={investment.value ? investment.value : "-"}
                          investments={
                            investment.investments
                              ? investment.investments
                              : "-"
                          }
                          recommendationISA={investment.transferISA}
                          recommendationGIA={investment.transferGIA}
                          charges={
                            investment.charges ? investment.charges : "-"
                          }
                          cgtAllowance={
                            context.state.nonClientData.Allowances
                              .capitalGainsTax
                          }
                        />
                      ))}
                    <div
                      className="sbrSubheading spacingLarge"
                      style={{ color: nextColour }}
                    >
                      Ways to improve your financial health
                    </div>
                    <div
                      className="sbrBlueSubSubHeading spacingSmall"
                      style={{ color: checkboxColour }}
                    >
                      Make sure you’re well protected
                    </div>
                    <div className="financialHealthCols">
                      <div className="financialHealthCol1">
                        <AmmoniteProtectedIcon />
                      </div>
                      <div className="financialHealthCol2">
                        {status === "success" && (
                          <div className="recommendationsParagraph recommendationsParagraph1">
                            {getLifeCover(
                              state.financialCheck.lifeCover,
                              state.financialCheck.incomeProtection
                            )}
                          </div>
                        )}
                        <div className="recommendationsParagraph recommendationsParagraph1">
                          It’s important to have cover in place, to help in the
                          events of, for example, a critical illness, an illness
                          or disability that means you can’t work, or if you
                          were to die - to protect you and your loved ones. This
                          is particularly important if you have financial
                          dependants and/or if you have a mortgage.
                        </div>
                        <div className="recommendationsParagraph recommendationsParagraph1">
                          Let us know if you would like us to put you in touch
                          with a protection specialist who can help you with
                          this aspect of your financial planning.
                        </div>
                      </div>
                    </div>
                    {state.financialCheck.hasMortgage &&
                      state.financialCheck.timeUntilMortgageDealEnds === 1 && (
                        <div>
                          <div
                            className="sbrBlueSubSubHeading"
                            style={{ color: checkboxColour }}
                          >
                            Make sure you have the best mortgage deal
                          </div>
                          <div className="financialHealthCols">
                            <div className="financialHealthCol1">
                              <AmmonitePropertyIcon />
                            </div>
                            <div className="financialHealthCol2">
                              {state.financialCheck.hasMortgage &&
                                state.financialCheck
                                  .timeUntilMortgageDealEnds && (
                                  <div className="recommendationsParagraph recommendationsParagraph1">
                                    Your mortgage deal runs out in{" "}
                                    {getMortgageTerm(
                                      state.financialCheck
                                        .timeUntilMortgageDealEnds
                                    )}
                                    .
                                  </div>
                                )}
                              <div className="recommendationsParagraph recommendationsParagraph1">
                                The impact of your mortgage rate can be huge
                                over the longer term, saving you alot of money.
                                When your mortgage deal comes up for renewal,
                                its important to secure the best deal possible.
                              </div>
                              <div className="recommendationsParagraph recommendationsParagraph1">
                                Let us know if you would like us to put you in
                                touch with a trusted mortgage broker, to help
                                you with this.
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    <div
                      className="sbrBlueSubSubHeading"
                      style={{ color: checkboxColour }}
                    >
                      Make sure you have an up to date Will and Lasting Power of
                      Attorney
                    </div>
                    <div className="financialHealthCols">
                      <div className="financialHealthCol1">
                        <AmmoniteWillIcon />
                      </div>
                      <div className="financialHealthCol2">
                        {state.financialCheck.will && (
                          <div className="recommendationsParagraph recommendationsParagraph1 ">
                            You have a Will.
                          </div>
                        )}
                        {!state.financialCheck.will && (
                          <div className="recommendationsParagraph recommendationsParagraph1 ">
                            You don't have a Will.
                          </div>
                        )}
                        <div className="recommendationsParagraph recommendationsParagraph1">
                          It’s important to make sure that you have a Will and
                          Lasting Power of Attorney in place, and that you
                          review these regularly - particularly when there are
                          changes in your financial or personal situation. Let
                          us know if you would like us to put you in touch with
                          someone that can help.
                        </div>
                      </div>
                    </div>
                    <div
                      className="sbrSubheading"
                      style={{ color: nextColour }}
                    >
                      Thank you
                    </div>
                    {getIsClient(
                      _initConfig.sBRConfig[sbrConfigIndex].thankYouText
                    ).map((text) => (
                      <div className="recommendationsParagraph recommendationsParagraph1 ">
                        {text.paragraph}
                      </div>
                    ))}
                    <div className="recommendationsParagraph recommendationsParagraph1 ">
                      {getSignature(
                        getIsClient(_initConfig.Advisors),
                        state.adviser - 1
                      )}
                    </div>
                    <div
                      className="sbrSubheading"
                      style={{ color: nextColour }}
                    >
                      Risk factors
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1 ">
                      <i>
                        The value of your shares/units and the income from them
                        will rise or fall as the value of the underlying
                        investments in which your money is invested changes.
                      </i>
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1 ">
                      <i>
                        When you sell your investment you may get back less than
                        you invested.
                      </i>
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1 ">
                      <i>
                        Movements in exchange rates may cause the value of your
                        investment to go up or down.
                      </i>
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1 ">
                      <i>
                        To obtain the benefit of equity investing, a long term
                        view is usually advised.
                      </i>
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1 ">
                      <i>
                        The fund performance is not guaranteed and therefore
                        past performance is no guide to future performance.
                      </i>
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1 ">
                      <i>
                        Recommendations are based on our understanding of
                        current tax legislation, which may be subject to change
                        and review.
                      </i>
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1 ">
                      <i>
                        The value of tax relief depends on your own financial
                        circumstances and may change in the future.
                      </i>
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1 ">
                      <i>
                        The information given is based upon our understanding of
                        current legislation and HM Revenue and Customs’
                        practice, both of which are subject to change.
                      </i>
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1 ">
                      {getIsClient(
                        _initConfig.sBRConfig[sbrConfigIndex]
                          .additionalRiskFactorText
                      ).map((text) => (
                        <div className="recommendationsParagraph recommendationsParagraph1 ">
                          <i>{text}</i>
                        </div>
                      ))}
                    </div>
                    <div
                      className="sbrSubheading"
                      style={{ color: nextColour }}
                    >
                      Cancellation rights
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1 ">
                      <i>
                        Once your investment plan is set up you have a legal
                        right to cancel should you change your mind, the period
                        is generally 30 days (which may reduce to 14 days for
                        ISA, Unit Trust and EIS investments and may increase to
                        60 days for annuities). The amount you get back may be
                        reduced by a decrease in value between making your
                        initial investment and up until your investment is sold.
                      </i>
                    </div>
                  </div>
                )}
            </div>
          </div>
        )}
    </div>
  );
}

export default SuitabilityReportPDF;
